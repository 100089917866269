module.exports = [{
      plugin: require('/home/circleci/repo/packages/brikl-shop-client/plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/home/circleci/repo/packages/brikl-shop-client/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
